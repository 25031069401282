<style scoped lang="scss">
  .notice{
    background: #fff;
    margin: 15px auto;
    padding: 20px;
	h2{
		text-align: center;
		font-size: 18px;
		padding-bottom: 15px;
		font-weight: 700;
	}
  }

</style>
<template>
  <div class="container">
    <!-- 新闻列表 -->
    <div class="notice w1150">
		<h2>{{tableData.title}}</h2>
		<div v-html="tableData.content"></div>		
	</div>
  </div>
</template>

<script>
import { duty } from "../utils/api"
import { Toast } from "vant"
export default {
  name: "Home",
  data: function() {
    return {
      tableData: []
    }
  },
  computed:{
    
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      const json = await duty({
        query: {},
        method: "get"
      });
      if (json.code == "200") {
		this.tableData = json.data
		console.log(22,this.tableData)
      } 
    },
    
  },
};
</script>