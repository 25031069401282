<style scoped lang="scss">
  .newdetail{
    margin: 15px auto;
	background: #fff;
	padding: 15px 30px;
    h2{
		text-align: center;
		font-size: 18px;
		padding-bottom: 15px;
		font-weight: 700;
	}
	.subtitle{
		text-align: center;
		span{
			font-size: 14px;
			color: #666;
			margin-right: 30px;
			em{
				color: #108EE9;
				font-style: normal;
			}
		}
	}
	.main-text{
		font-size: 14px;
		color: #333;
		line-height: 150%;
		text-indent: 28px;
		border-bottom: 1px solid #eee;
		padding: 15px 0;
	}
  }

</style>
<template>
  <div class="container">
    <!-- 新闻详情 -->
	<div class="newdetail w1150">
		<h2>{{ dataList.title }}</h2>
		<div class="subtitle">
			<span>时间：{{ dataList.createTime }}</span>
			<span>发布人：<em>{{ dataList.author }}</em></span>
		</div>
		<div class="main-text" style="border-bottom: 0;" v-html="dataList.content"></div>
	</div>
  </div>
</template>

<script>
import { noticedetail } from "../utils/api"
import { Toast } from "vant"
export default {
  name: "Home",
  data: function() {
    return {
	  dataList: []
    }
  },
  computed:{
    
  },
  created() {

  },
  mounted() {
	this.getList(this.$route.query.id)
  },
  methods: {

    async getList(id) {
      const json = await noticedetail({
        query: {'id':id},
        method: "get"
      });
      if (json.code == "200") {
        this.dataList = json.data
      } 
    },
    
  },
};
</script>