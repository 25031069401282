<template>
  <div class="container">
    <div class="login">
      <h2>欢迎登录</h2>
      <el-form status-icon ref="ruleForm" label-width="60px" @keyup.enter.native="submitForm()" class="demo-ruleForm">
        <el-form-item label="账号">
          <el-input type="text" v-model="params.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="params.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input type="text" v-model="params.captcha" autocomplete="off" maxlength="5" style="float:left; width:150px;"></el-input>
          <img class="uuid" :src="captchaUrl" @click="getCaptcha()">
        </el-form-item>
        <el-form-item style="margin-top:10px;width: 100%;">
        </el-form-item>
        <el-button type="primary" @click="submitForm()" style="width:100%">登录</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login, url } from "../utils/api"
import { Toast } from "vant"
export default {
  name: "Login",
  data: function() {
    return {
      params: {
        username: '',
        password: '',
        captcha: '',
        uuid: ''
      },
      captchaUrl: ''
    }
  },
  computed:{

  },
  created() {

  },
  mounted() {
    this.params.uuid = this.getUUID()
    this.getCaptcha()
  },
  methods: {
    async submitForm() {
      if(!this.params.username || !this.params.password) {
        Toast('账号密码不能为空')
        return;
      }
      if(!this.params.captcha){
        Toast('验证码不能为空')
        return;
      }
      const json = await login({
        query: this.params,
        method: "post"
      });
      if (json.code == "200") {
        localStorage.setItem("dqtoken", json.data.token);
        localStorage.setItem("pic", json.data.pic);
        localStorage.setItem("username", json.data.username);
        // this.$router.replace("/");
        window.location.href = '/'
      } else {
        Toast(json.msg)
      }
    },
    getUUID(){
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
      })
    },
    // 获取验证码
    getCaptcha () {
        this.params.uuid = this.getUUID()
        this.captchaUrl = url + `/captcha.jpg?uuid=${this.params.uuid}`
    }
  },
};
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: 100vh;
    background: #2e3e9c;
      .login{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 400px;
        height: 360px;
        padding: 20px;
        background: #fff;
        box-sizing: border-box;
        margin: -180px 0 0 -200px;
        z-index: 2;
        h2{
          text-align: center;
          padding: 0 0 15px 0;
        }
        .uuid{
          float: left;
          width: 130px;
          height: 40px;
          margin-left: 20px;
          cursor: pointer;
        }
    }
  }

</style>
