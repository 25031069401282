<style scoped lang="scss">
  .newdetail{
    margin: 15px auto;
	background: #fff;
	padding: 15px 30px;
    h2{
		text-align: center;
		font-size: 18px;
		padding-bottom: 15px;
		font-weight: 700;
	}
	.subtitle{
		text-align: center;
		span{
			font-size: 14px;
			color: #666;
			margin-right: 30px;
			em{
				color: #108EE9;
				font-style: normal;
			}
		}
	}
	.main-text{
		font-size: 14px;
		color: #333;
		line-height: 150%;
		text-indent: 28px;
		border-bottom: 1px solid #eee;
		padding: 15px 0;
	}
  }
.context{
	p{
		font-size: 14px;
		color: #333;
		line-height: 150%;
		margin-bottom: 10px;
	}
	p.mb20{
		margin-bottom: 20px;
	}
}

.sub-text{
	padding: 15px 0;
	border-bottom: 1px solid #eee;
	h4{
		color: red;
		font-size: 14px;
		padding-bottom: 20px;
	}
}


</style>
<template>
  <div class="container">
    <!-- 新闻详情 -->
	<div class="newdetail w1150">
		<h2>{{dataList.title}}</h2>
		<div class="subtitle">
			<span>发布网站：<em>{{ dataList.website }}</em></span>
			<span>时间：{{ dataList.sourceTime }}</span>
			<span>作者：<em>{{ dataList.author }}</em></span>
		</div>
		<div class="main-text">
			<p>{{ dataList.abstractContext }}</p>
			<p>{{ dataList.remark }}</p>
		</div>
		<div class="sub-text">
			<h4>短信格式：</h4>
			<div class="context">
				<p>{{dataList.attributeLabel}}</p>
				<br/>
				<p>{{dataList.website}} <span v-if="dataList.sourceType !== '03'">《{{dataList.title}}》</span> {{showTypeLabel}}</p>
				<p>来源：{{dataList.author}} <span v-if="dataList.sourceType === '03' && dataList.weibofans !== 0">（粉丝数：{{dataList.weibofans}}）</span></p>
				<p>摘要：{{ dataList.abstractContext }}</p>
				<br/>
				<p v-if="dataList.remark">备注：{{ dataList.remark }}</p>
				<p>链接：<a :href="dataList.url" target="_blank" style="color:#409EFF">{{dataList.url}}</a></p>
			</div>
			<div style="text-align: center;">
				<el-button type="primary"
				v-clipboard:copy="copyText1"
				v-clipboard:success="onCopyMsg"
				v-clipboard:error="onErrorMsg"
				>复制内容</el-button>
				</div>
		</div>
		<div class="sub-text">
			<h4>报告格式：</h4>
			<div class="context">
				<p>{{dataList.attributeLabel}}</p>
				<p>{{dataList.title}}</p>
				<p>类型：{{dataList.typeLabel}}</p>
				<p>出处： {{dataList.website}}</p>
				<p class="mb20">来源：{{dataList.author}} <span v-if="dataList.sourceType === '03' && dataList.weibofans !== 0">（粉丝数：{{dataList.weibofans}}）</span></p>
				<p class="mb20">链接：<a :href="dataList.url" target="_blank" style="color:#409EFF">{{dataList.url}}</a></p>
				<p class="mb20">摘要：{{ dataList.abstractContext }}</p>
				<p v-if="dataList.remark">备注：{{ dataList.remark }}</p>
			</div>
			<div style="text-align: center;">
				<el-button type="primary"
				v-clipboard:copy="copyText2"
				v-clipboard:success="onCopyPublic"
				v-clipboard:error="onErrorPublic"
				>复制内容</el-button>
				</div>
		</div>
	</div>
  </div>
</template>

<script>
import { newsdetail } from "../utils/api"
import { Toast } from "vant"
export default {
  name: "Home",
  data: function() {
    return {
	  dataList: [],
	  showTypeLabel: '',
	  copyText1: '',
	  copyText2: ''
    }
  },
  computed:{

  },
  created() {

  },
  mounted() {
	this.getList(this.$route.query.id)
  },
  methods: {
    async getList(id) {
      const json = await newsdetail({
        query: {'infoId':id},
        method: "get"
      });
      if (json.code == "200") {
		  this.dataList = json.data
		  if (!this.dataList.typeLabel) {
              this.dataList.typeLabel = ''
		  }
          if (this.dataList.showType === 2) {
              this.showTypeLabel = '文字链'
          } else if (this.dataList.showType === 3) {
              this.showTypeLabel = '焦点图'
          }
          if (this.dataList.sourceType === '03') {
              let fansText = ''
			  let remarkText = ''
			  if (this.dataList.weibofans > 0) {
                  fansText = '（粉丝数：'+this.dataList.weibofans+'）'
			  }
			  if (this.dataList.remark) {
                  remarkText = '备注：'+this.dataList.remark
			  }
              this.copyText1 = this.dataList.attributeLabel+'\r\n'+'\r\n'+this.dataList.website+'\r\n来源：'+this.dataList.author+fansText
				  					+'\r\n摘要：' +this.dataList.abstractContext+'\r\n'+'\r\n'
                  					+remarkText+'\r\n链接：'+this.dataList.url
              this.copyText2 = this.dataList.attributeLabel+'\r\n'+this.dataList.title+'\r\n类型：'+this.dataList.typeLabel+'\r\n出处：'
								  	+this.dataList.website+'\r\n来源：'+this.dataList.author+fansText
				  					+'\r\n链接：'+this.dataList.url+'\r\n摘要：'
								  	+this.dataList.abstractContext+'\r\n'+remarkText
		  }else{
              let remarkText = ''
              if (this.dataList.remark) {
                  remarkText = '备注：'+this.dataList.remark
              }
              this.copyText1 = this.dataList.attributeLabel+'\r\n'+'\r\n'+this.dataList.website+' 《 '+this.dataList.title+'》 '
				  					+this.showTypeLabel+'\r\n来源：'+this.dataList.author+'\r\n摘要：'+this.dataList.abstractContext+'\r\n'+'\r\n'
				  					+remarkText+'\r\n链接：'+this.dataList.url
			  this.copyText2 = this.dataList.attributeLabel+'\r\n'+this.dataList.title+'\r\n类型：'+this.dataList.typeLabel+'\r\n出处：'
				  					+this.dataList.website+'\r\n来源：'+this.dataList.author+'\r\n链接：'+this.dataList.url+'\r\n摘要：'
				  					+this.dataList.abstractContext+'\r\n'+remarkText
		  }
      }
	},
	onCopyMsg(){
		Toast('复制成功')
	},
	onErrorMsg(){
		Toast('复制失败')
	},
	onCopyPublic(){
		Toast('复制成功')
	},
	onErrorPublic(){
		Toast('复制失败')
	}
  }
};
</script>
