<style scoped lang="scss">
.newdetail{
	margin: 15px auto;
	background: #fff;
	padding: 15px 30px;
  .pip{
	font-size: 14px;
	color: #333;
  }
  h2{
    text-align: center;
    font-size: 18px;
    padding-bottom: 15px;
    font-weight: 700;
  }
}
  
  
</style>
<template>
  <div class="container">
    <div class="newdetail w1150">
				<h2>{{ dataList.title }}</h2>
				<div class="main-text" style="border-bottom: 0;" v-html="dataList.content"></div>
			</div>
  </div>
</template>

<script>
import { jobLog } from "../utils/api"
import { Toast } from "vant"
export default {
  name: "Home",
  data: function() {
    return {
      dataList: []
    }
  },
  computed:{
    
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      const json = await jobLog({
        query: {},
        method: "get"
      });
      if (json.code == "200") {
        this.dataList = json.data
      } else {

      }
    },
    
  },
};
</script>