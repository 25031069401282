<style scoped lang="scss">
  .notice{
    background: #fff;
    margin: 15px auto;
    padding: 20px;
    h2{
		text-align: center;
		font-size: 18px;
		padding-bottom: 15px;
		font-weight: 700;
	}
  }

</style>
<template>
  <div class="container">
    <!-- 新闻列表 -->
    <div class="notice w1150">
				<h2>公告列表</h2>
				<el-table
				  :data="tableData"
				  style="width: 100%">

				  <el-table-column
					prop="title"
					label="标题"
					:show-overflow-tooltip="true"
					width="480"
					>
				  </el-table-column>

				  <el-table-column
					prop="author"
					label="发布人"
					>
				  </el-table-column>

				  <el-table-column
					prop="createTime"
					label="发布时间">
				  </el-table-column>

				  <el-table-column
					label="操作"
					>
					<template slot-scope="scope">
					  <el-button @click="handleDetail(scope.row)" type="text" size="small">查看详情</el-button>
					</template>
				  </el-table-column>

				</el-table>

				<!-- 分页 -->
				<el-pagination
				  :current-page.sync="queryParams.current"
				  :page-sizes="[10, 20, 50, 100]"
				  :page-size.sync="queryParams.size"
				  layout="total, sizes, prev, pager, next, jumper"
				  :total="total"
				  @current-change="currentChangeHandle"
				  @size-change="sizeChangeHandle"
				/>
			</div>
  </div>
</template>

<script>
import { notice } from "../utils/api"
import { Toast } from "vant"
export default {
  name: "Home",
  data: function() {
    return {
      queryParams: {
        current: 1,
        size: 10
	  },
      total: 0,
      tableData: []
    }
  },
  computed:{

  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    handleDetail(row){
      this.$router.push({ path: "noticedetails", query: { id:  row.id} });
    },
    async getList() {
      const json = await notice({
        query: this.queryParams,
        method: "get"
      });
      if (json.code == "200") {
        this.tableData = json.data.records
        this.total = json.data.total
		this.queryParams.current = json.data.current
      }
    },
    // 每页数
    sizeChangeHandle (val) {
        this.queryParams.size = val
        this.queryParams.current = 1
        this.getList()
    },
    // 当前页
    currentChangeHandle (val) {
        this.queryParams.current = val
        this.getList()
    },
  },
};
</script>
